<template>
  <div>
    <a-card title="属性详情" style="width: 80%; margin: 2% auto">
      <template slot="extra">
        <a-select
          v-model="languageSelect"
          allowClear
          placeholder="请选择翻译语言"
          showSearch
          optionFilterProp="children"
          @change="handleLanguage"
          :style="{ width: '8vw' }"
        >
          <a-select-option v-for="(language, key) in languageOptions" :key="key" :value="language.value">{{
            language.label
          }}</a-select-option>
        </a-select>
      </template>
      <a-descriptions bordered>
        <a-descriptions-item label="属性ID">{{ form.id }} </a-descriptions-item>
        <a-descriptions-item label="属性名称"> {{ form.description.defaultMessage }} </a-descriptions-item>
        <a-descriptions-item label="属性标识符"> {{ form.identifier }} </a-descriptions-item>
        <a-descriptions-item label="属性定义唯一标识"> {{ form.encode }} </a-descriptions-item>
        <a-descriptions-item label="属性读写权限">
          <div v-if="form.accessMode === $constRepository.property.accessMode.readOnly">只读</div>
          <div v-else-if="form.accessMode === $constRepository.property.accessMode.writeOnly">只写</div>
          <div v-else-if="form.accessMode === $constRepository.property.accessMode.readWrite">可读写</div>
          <div v-else>未知</div>
        </a-descriptions-item>
        <a-descriptions-item label="节点类型">
          <div v-if="form.propertyType === $constRepository.property.propertyType.root">父节点</div>
          <div v-else-if="form.propertyType === $constRepository.property.propertyType.child">子节点</div>
          <div v-else>未知</div></a-descriptions-item
        >
        <a-descriptions-item label="属性类型">
          <span>{{ propertyType(form) }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="编辑方式">
          <span>{{ propertyEditMode(form) }}</span>
        </a-descriptions-item>
        <a-descriptions-item label="默认值">
          <span>{{ form.type.defaultValue || '无' }}</span>
        </a-descriptions-item>
        <template v-if="form.type.editMode == $constRepository.productFormItem.editMode.input">
          <a-descriptions-item label="范围">
            <span v-if="form.type.type !== $constRepository.product.DATA_PRIMITIVE_TYPES.BOOL">{{
              `${form.type.range.min}-${form.type.range.max}`
            }}</span>
            <span v-else> 无 </span>
          </a-descriptions-item>
        </template>
        <template v-if="form.type.editMode == $constRepository.productFormItem.editMode.select">
          <a-descriptions-item label="选项">
            <p v-for="(option, index) in form.type.options" :key="index">
              {{ `${option.value}:${option.description.defaultMessage}` }}
            </p>
          </a-descriptions-item>
        </template>
      </a-descriptions>
    </a-card>
    <!-- <a-card title="属性描述" class="" style="width: 80%; margin: 0 auto; margin-bottom: 2%；">
      <vue-json-editor
        class="default-message-edit"
        v-model="form.description"
        :showBtns="false"
        :mode="'code'"
        lang="zh"
      />
    </a-card> -->
    <a-card title="属性数据" style="width: 80%; margin: 0 auto; margin-bottom: 2%">
      <vue-json-editor v-model="form" :showBtns="false" :mode="'code'" lang="zh" style="height: 800px" />
      <a-button type="primary" class="creator-btn" @click="$router.back()">返回</a-button>
    </a-card>
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor';

export default {
  name: 'PropertyDetail',
  components: { vueJsonEditor },
  async mounted() {
    if (this.$route.params.id) {
      await Promise.all([this.listPropertiesById(this.$route.params.id), this.getMetaData()]);
    }
  },
  data() {
    return {
      languageSelect: this.$constRepository.language.FILTER_OPTION_ZH_CN.value,
      languageOptions: this.$constRepository.language.FILTER_OPTIONS,
      filterOptions: this.$constRepository.property.FILTER_NODES,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      metaData: {},
      form: {
        identifier: null,
        name: null,
        propertyType: null,
        accessMode: null,
        description: {
          key: '',
          defaultMessage: '',
        },
        type: {
          type: undefined,
          editMode: undefined,
          defaultValue: '',
          range: {
            min: '',
            max: '',
          },
          options: [
            {
              value: '',
              description: {
                key: '',
                defaultMessage: '',
              },
            },
          ],
          defaultValueDescription: {
            key: '',
            defaultMessage: '',
          },
        },
      },
    };
  },
  methods: {
    async handleLanguage() {
      await this.listPropertiesById(this.$route.params.id);
    },
    async listPropertiesById(ids) {
      const params = {
        id: ids,
      };
      const headers = {
        'Accept-Language': this.languageSelect,
      };
      const response = await this.$apiManager.property.getPropertiesById(params, headers);
      this.form = response.data[0];
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
  },
  computed: {
    propertyType() {
      return (property) => {
        const res = this.metaData.dataPrimitiveTypes?.find((type) => type.value === property.type.type);
        if (res) {
          return res.name;
        }
        return '';
      };
    },
    propertyEditMode() {
      return (property) => this.$constRepository.productFormItem.editModeText[property.type.editMode] || '';
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.jsoneditor-poweredBy {
  display: none;
}
// /deep/.jsoneditor-outer {
//   height: 25vw;
// }
/deep/ .jsoneditor-vue {
  height: 100%;
}
.creator-btn {
  margin-top: 2%;
}
.default-message-edit {
  height: 8vw;
  /deep/.jsoneditor-outer {
    height: 8vw;
  }
}
</style>
